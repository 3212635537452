import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Arrow from '../images/arrow.inline.svg'
import { Trans } from 'gatsby-plugin-react-i18next'


const NewsBlock = (props) => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        sort: { fields: [date], order: DESC } # limit: $postsPerPage # skip: $offset
      ) {
        nodes {
          uri
          date(formatString: "DD.MM.YYYY")
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  destacado: gatsbyImageData(
                    width: 690
                    aspectRatio: 1.77777777778
                    formats: [AUTO, WEBP, AVIF]
                  )
                  thumb: gatsbyImageData(
                    height: 250
                    aspectRatio: 1
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  // Array of all news articles
  const allNews = data.allWpPost.nodes

  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 9)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 9)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <>
      <section className='border-b border-black destacado bg-green'>
        <div className='container grid px-4 mx-auto lg:divide-x lg:grid-cols-2'>
          <div className='pt-20 lg:py-40 lg:pr-14'>
            {list[0].featuredImage && (
              <GatsbyImage
                image={getImage(
                  list[0].featuredImage.node.localFile.childImageSharp.destacado
                )}
                alt={list[0].title}
                className='flex-none w-full'
              />
            )}
          </div>

          <div className='flex flex-col pt-12 pb-40 lg:pt-40 post__summary'>
            <div className='flex flex-col text-sm lg:pl-14 pb-14 lg:text-base post__meta'>
              <time className='mb-8 '>{list[0].date}</time>
              {/* <p>Categoría, Categoría</p> */}

              <h2 className='text-2xl font-extrabold text-white lg:text-4xl'>
                {list[0].title}
              </h2>
            </div>
            <Link
              className='flex items-center text-lg uppercase lg:mt-10 lg:ml-14'
              to={list[0].uri}
            >
              <Trans>Leer más</Trans>
              <Arrow className='w-5 ml-2 text-black fill-current'></Arrow>
            </Link>
          </div>
        </div>
      </section>
      <section className='pt-20 pb-40 bg-white lg:py-40'>
        <div className='grid max-w-3xl grid-cols-2 px-4 mx-auto mb-12 -mt-40 lg:px-0 lg:grid-cols-3 lg:-mt-60 gap-x-8 gap-y-16'>
          {list.map((article) => {
            return (
              <article className='text-xs post lg:text-base' key={article.id}>
                <Link to={article.uri} className='flex flex-col h-full'>
                  {article.featuredImage ? (
                    <GatsbyImage
                      image={getImage(
                        article.featuredImage.node.localFile.childImageSharp
                          .thumb
                      )}
                      alt={article.title}
                      className='mb-2'
                    />
                  ) : (
                    <div className='mb-1 bg-green w-60 h-60'></div>
                  )}

                  <h2 className='mb-4'>{article.title}</h2>
                  <span className='mt-auto font-bold border-b-4 place-self-end border-green'>
                    {article.date}
                  </span>
                </Link>
              </article>
            )
          })}
        </div>
        {hasMore && (
          <button
            className='flex items-center justify-center px-6 py-3 mx-auto mb-10 text-2xl font-semibold text-white uppercase border-none rounded-full button-noticias bg-orange'
            onClick={handleLoadMore}
          >
            <Trans>Cargar más noticias</Trans>
          </button>
        )}
      </section>
    </>
  )
}

export default NewsBlock

