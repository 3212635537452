import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Seo from '../components/seo'

import NewsBlock from '../components/news-block'


const PageNoticias = (props) => {




  return (
    <Layout location={props.location}>
      <Helmet bodyAttributes={{ class: 'page-header-green' }}></Helmet>

      <Seo title='Noticias' />
      <NewsBlock />
    </Layout>
  )
}

export default PageNoticias
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`